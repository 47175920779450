import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
import PortfolioList from 'sections/portfolio/PortfolioList.js'
import Metadata from '../../constants/metadata'

class Index extends React.Component {
  render() {
    const { site } = this.props.data
    return (
      <div>
        <Helmet>
          <title>{Metadata.Portfolio.title}</title>
          <meta name="title" content={Metadata.Portfolio.metaTitle} />
          <meta name="description" content={Metadata.Portfolio.metaDescription} />
        </Helmet>
        <Layout
          isHome={false}
          sections={Metadata.Navigation.Links}
        >
          <PortfolioList />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`