import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Row, Col, Container } from 'react-bootstrap'
import PortfolioItem from 'sections/portfolio/PortfolioItem.js'
import Section from 'components/section'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import AnimatedHeading from 'components/animated-heading'
import AnimationContainer from 'components/animation-container'
import { Theme } from '../../constants/variables'
import Metadata from '../../constants/metadata'

class PortfolioList extends React.Component {

  render() {
    const styles = {
      padding: Theme.spacing.two,
      backgroundColor: Theme.color.dark,
      textAlign: 'center'
    }
    return (
      <Section id="portfolio" style={styles}>
        <Row style={{ marginTop: Theme.spacing.max }}>
          <Col md={12}>
            <Container style={{ marginBottom: Theme.spacing.three }}>
              <AnimatedHeading text={Metadata.Portfolio.pageHeader} />
            </Container>
            <AnimationContainer animation="fadeIn">
              {this.portfolio()}
            </AnimationContainer>
          </Col>
        </Row>
      </Section>
    )
  }

  portfolio() {
    const { projects } = this.props
    return projects.map((edge, index) => {
      const project = edge.node
      const link = `/work/${project.slug.current}`
      return (
        <PortfolioItem
          key={index}
          index={index}
          id={project.id}
          link={link}
          title={project.title}
          headline={project.headline}
          subheadline={project.subheadline}
          coverPhoto={project.coverPhoto}
          type="col"
          tags={project.tags}
          style={{ marginBottom: Theme.spacing.four }}
        />
      )
    })
  }
}

export const query = graphql`
  query PortfolioQuery {
    projects: allSanityProject {
      edges {
        node {
          id
          slug { current }
          title
          metaDescription
          headline
          subheadline
          coverPhoto { asset { url } }
          headlinePhoto { asset { url } }
          tags
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={query}
    render={({ projects }) => <PortfolioList projects={projects.edges} {...props} />}
  />
)