import React from 'react'
import handleViewport from 'react-in-viewport'
import styled, { keyframes } from 'styled-components'
import { Heading1 } from 'components/text'
import { Theme } from '../../constants/variables'

class AnimatedHeadingInterface extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            inViewport: false,
            isMobile: false,
            animationComplete: false,
            animate: false,
            force: false
        }
    }

    componentDidMount() {
        const isMobile = /iPhone|iPad|iPad Mini|iPod|Android/i.test(window.navigator.userAgent)
        this.setState({ isMobile })
    }

    componentDidUpdate() {
        if (this.state.inViewport !== this.props.inViewport && !this.state.force) {
            if (!this.state.animationComplete) {
                this.setState({ inViewport: this.props.inViewport, animate: true, force: true })
                let delay = 1
                this.props.text.split(" ").forEach(function (value, index) {
                    value.split("").forEach(function (v, i) {
                        ++delay
                    })
                })
                setTimeout(() => {
                    this.setState({ animationComplete: true })
                }, (delay * 200) + 500)
            }
        }
        if (this.state.inViewport !== this.props.inViewport && this.state.force) {
            this.setState({ animationComplete: true, force: false })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.animationComplete && !this.props.inViewport) {
            return false
        } else {
            return true
        }
    }

    render() {
        if (this.state.isMobile) {
            return (
                <Heading1 color={Theme.color.primary}>{this.props.text}</Heading1>
            )
        }
        else {
            return (
                <Heading1 className="heading">
                    {this.animate()}
                </Heading1>
            )
        }
    }

    animate() {
        const Animation = keyframes`
            0% {
                opacity: 0;
            }
            100% {
                transform: translate(0,0);
                opacity: 1;
            }
        `

        const AnimatedLetter = styled.span`
            transform: translate(0, -100px);
            display: inline-block;
            transition: 1s;
            opacity: 0;
            font-size: ${Theme.fontSize.largest};
            line-height: ${this.props.lineHeight ? this.props.lineHeight : "90px"};
            font-family: Teko;
            text-transform: uppercase;
            &.animationComplete {
                transform: translate(0, 0);
                opacity: 1;
            }
            &.animate {
                animation: ${Animation} forwards 0.5s;
            }
            @media (max-width: 767px) {
                font-size: ${Theme.fontSize.large};
                line-height: 45px;
            }
        `
        const Space = styled.span`
            margin: 0 ${this.props.space ? this.props.space : "10px"};
            @media (max-width: 767px) {
                margin: 0 5px;
            }
        `
        let time = 0;
        return this.props.text.split(" ").map((value, index) => {
            return value.split("").map((v, i) => {
                ++time
                const next = time * 100
                return i + 1 !== value.length ? <AnimatedLetter key={i} style={{ animationDelay: `${next}ms`, color: index % 2 !== 0 || this.props.color ? Theme.color.primary : "#fff" }} className={!this.state.animationComplete ? this.state.animate ? "animate" : "" : "animationComplete"}>{v}</AnimatedLetter> : <span key={i}><AnimatedLetter style={{ animationDelay: `${next}ms`, color: index % 2 !== 0 || this.props.color ? Theme.color.primary : "#fff" }} className={!this.state.animationComplete ? this.state.animate ? "animate" : "" : "animationComplete"}>{v}</AnimatedLetter><Space /></span>
            })
        })
    }

}
const AnimatedHeading = handleViewport(AnimatedHeadingInterface);

export default AnimatedHeading