import React from 'react'
import styled, { keyframes } from 'styled-components'
import Tilt from 'react-tilt'
import { Link } from "gatsby"
import { Heading2, Heading3, Heading4, ParagraphText } from 'components/text'
import { Theme } from '../../constants/variables'

class PortfolioItem extends React.Component {
    // Render image within the card.
    // Accepts image type from Sanity CMS.
    renderImage(image) {
        const Image = styled.img`
            width: 100%;
            height: 100%
            object-fit: cover;
            z-index: -1000;
            transition: .5s;
        `
        const url = image && image.asset && image.asset.url
        return url ? (<Image src={url} />) : ('')
    }

    renderTags(tags) {
        // Only use the first three tags when rendering an item.
        let tagsShort = tags.slice(0, 3)
        // Concat into single string separated by dots
        let tagsText = tagsShort.join(' • ')
        // Render tag text in banner container
        return (<ParagraphText style={{
            color: Theme.color.white,
            fontSize: Theme.fontSize.smallest,
            fontWeight: 'bold'
        }}>{tagsText}</ParagraphText>)
    }

    render() {
        // Animation keyframes
        const MoveUp = keyframes`
            0% { transform: translateY(0); }
            100% { transform: translateY(-20px); }
        `
        const MoveDown = keyframes`
            0% { transform: translateY(0); }
            100% { transform: translateY(20px); }
        `
        // Non-featured items have slightly dimmed opacity.
        const opacityStart = !this.props.featured ? 0.8 : 1.0;
        const opacityEnd = 1.0;

        // Featured Project: Wrapper for hover state on text banner
        const HoverContainer = styled.div`
            opacity: 0;
            &:hover {
                opacity: ${opacityEnd};
            }
        `
        // Container for title, subtitle. Container overlays card image.
        const TextContainer = styled.div`
            color: rgba(0, 0, 0, 1.0);
            background-color: rgba(22, 28, 39, 0.5);
            padding: ${Theme.spacing.two};
            position: absolute;
            bottom: 0%;
            left: 50%;
            width: 100%;
            transform: translate(-50%, 0%);
        `
        // Container for entire card item.
        const Item = styled.div`
            img {
                opacity: ${opacityStart};
            }
            text-align: center;
            overflow: hidden;
            border-radius: 20px;
            max-width: 800px;
            max-height: 500px;
            margin: 0 auto;
            margin-top: 20px;
            margin-bottom: 20px;
            height: auto;
            opacity: ${opacityStart};
            &.move-up {
                animation: ${MoveUp} 5s infinite alternate;
            }
            &.move-down {
                animation: ${MoveDown} 5s infinite alternate;
            }
            &:hover {
                img {
                    transform: scale(1.1);
                    opacity: ${opacityEnd};
                }
                .BannerTitle {
                    opacity: ${opacityEnd};
                    background-color: red;
                }
                -webkit-animation: fade-in 0.5s forwards;
                -moz-animation: fade-in 0.5s forwards;
                -o-animation: fade-in 0.5s forwards;
                animation: fade-in 0.5s forwards;
            }
            @-webkit-keyframes fade-in {
                0% {
                    opacity: ${opacityStart};
                }
                100% {
                    opacity: ${opacityEnd};
                }
            }
            @-moz-keyframes fade-in {
                0% {
                    opacity: ${opacityStart};
                }
                100% {
                    opacity: ${opacityEnd};
                }
            }
            @-o-keyframes fade-in {
                0% {
                    opacity: ${opacityStart};
                }
                100% {
                    opacity: ${opacityEnd};
                }
            }
            @keyframes fade-in {
                0% {
                    opacity: ${opacityStart};
                }
                100% {
                    opacity: ${opacityEnd};
                }
            }
        `
        return (
            <Link to={this.props.link} target="_blank" rel="noopener noreferrer">
                <Tilt options={{ scale: 1, max: 10 }}>
                    <Item className={`${this.props.index % 2 === 0 ? "move-up" : "move-down"}`} id={`portfolio-item-${this.props.index}`} style={this.props.style}>
                        {/* Render card photo */}
                        {this.renderImage(this.props.coverPhoto)}

                        {/* Customize card w/ hover state if featured */}
                        {this.props.featured ?
                            (
                                <HoverContainer className="BannerTitle">
                                    <TextContainer>
                                        <Heading4 style={{ color: 'white' }}>See What We Did <span>→</span></Heading4>
                                    </TextContainer>
                                </HoverContainer>) :
                            (
                                <TextContainer>
                                    <Heading3>{this.props.title}</Heading3>

                                    {this.props.tags ? this.renderTags(this.props.tags) : ('')}
                                </TextContainer>
                            )}
                    </Item>
                </Tilt>
            </Link >
        )
    }
}

export default PortfolioItem